@charset "utf-8";

html {
  -webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
	   -moz-box-sizing: inherit;
	        box-sizing: inherit;
}
html {
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@import url('//cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/css/yakuhanjp.min.css');
@import url('https://fonts.googleapis.com/css?family=Roboto');

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

body  {
  background:inherit;
  font-family: YakuHanJP,"Roboto","Yu Gothic", YuGothic, sans-serif;
  font-weight: normal;
  font-weight: 500;
  color: #222222;
  font-size: 1.4rem;
  // overflow-y:scroll;
  -webkit-text-size-adjust: 100%;
}


// Ie専用指定
@media all and (-ms-high-contrast:none){
    html
    {
        // font-family: 'メイリオ', 'Meiryo', sans-serif;
    }
}

@media only screen and (min-width: 767px) {
  body  {
    font-size:1.8rem;
  }
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
全頁共通css
–––––––––––––––––––––––––––––––––––––––––––––––––– */

  h1,h2,h3,h4,h5,h6,strong,.font_bold{
    clear:both;
    font-weight: 500;
  }
  p{
    line-height:1.8;
  }

  img{
    height:auto;
    vertical-align: bottom;
    max-width: 100%;
  }

  a {
    color: #222;
    outline: none;
    text-decoration: none;
    &:hover{
      color: #222;
    }
  }
  strong{
    font-weight:bold;
  }
  small{
    font-size:1.2rem;
  }

  address{
    font-style: normal;
  } 
  blockquote {
    background-color: #eee;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom:15px;
    padding: 30px;
    position: relative;
  }

  blockquote:before {
    content: "“";
    font-size: 600%;
    line-height: 1em;
    font-family: "ＭＳ Ｐゴシック",sans-serif;
    color: #999;
    position: absolute;
    left: 0;
    top: 0;
  }


 /* ––––––––––––––––––––––––––––––––––––––––––––––––––
管理画面でつけるclassようのcss
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.aligncenter {
  display: block;
  margin: 0 auto;
}

.alignright { 
  float: right;
  margin:0 0 20px 30px;
  padding:0;
}

.alignleft { 
  float: left;
  margin:0 30px 20px 0;
  padding:0;
}

article h1,article h2,article h3,article h4,article h5,article h6{
  clear: both;
} 
 
img[class*="wp-image-"],
img[class*="attachment-"] {
    height: auto;
    max-width: 100%;
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
全頁共通PC/SP切り替えcss
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.sp_none{
  display:none;
}
.pc_none{
  display:block;
}
@media only screen and (min-width: 767px) {
  .sp_none{
    display:block;
  }
  .pc_none{
    display:none;
  }
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
トップページ共通サイト幅css
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrap_section{
  .inner_content{
    padding:40px 30px;
  }
}
@media only screen and (min-width: 767px) {
  .wrap_section{
  .inner_content{
    margin:0 auto;
    max-width:1050px;
    padding:25px;
  }
}

}
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
下層ページ共通サイト幅css
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.inner_pages{
  main{
    overflow:hidden;
    #wrap{
      #contents{
        padding: 50px 0;
      }
      .wrap_shop{
        .inner_content{
          padding-left:0;
          padding-right:0;
        }
      }
    }
  }
}
@media only screen and (min-width: 767px) {
  .inner_pages{
    main{
      #wrap{
        #contents{
          padding: 100px 0;
        }
        .wrap_shop{
          .inner_content{

          }
        }
      }
    }
  }
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
編集ボタン
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#webmaster{
  margin-top:30px;
  text-align: center;
  a{
    background-color: #eee;
    padding: 10px 15px;
    display:block;
  }
}


/* ––––––––––––––––––––––––––––––––––––––––––––––––––
全頁共通レイアウト
–––––––––––––––––––––––––––––––––––––––––––––––––– */

main{
  overflow:hidden;
  article{

  // margin-bottom:30px;
  a {
    border-bottom: dotted 1px #222;
  }

  a img {
    border:none;
    margin-bottom: -1px ;
  }
  p{
    &+p{
      margin-top:15px
    }
  }

  ul,ol{
    margin-top:15px;
    li{
      margin-bottom:5px;
      margin-left:20px;
    }
    &+p{
      margin-top:15px
    }
  }
  table{
    border-top: solid 1px #d6d6d6;
    border-left: solid 1px #d6d6d6;
    margin: 15px auto;
    width: 100%;
    th,td{
      border-bottom: solid 1px #d6d6d6;
      border-right: solid 1px #d6d6d6;
      display:table-cell;
      max-width:530px;
      &:last-child{
      }
    }
    th{
      background-color:#efefef;
      font-weight: 500;
      padding:10px;
    }
    td{
      padding:10px 15px;
      vertical-align:top;
    }
    &.free_table{
      border:0;
      th,td{
        border:0;
      }
    }
  }
  .responsive-table{
    table{
      min-width:768px;
    }
  }


  h1{
    font-size: 2rem;
  }

  h2,h3,h4,h5,h6{
    margin-bottom:15px;
    font-weight:600;
  }
  h2{
    font-size:2.8rem;
    text-align:center;
  }
  h3{
    font-size:2.2rem;
    line-height:1.1;
    padding:0px 0px 0px 15px;
    position:relative;
    &:before{
      background:#3c3c3c url(../images/powder_bg.png) no-repeat 43% 30%/185px;
      content:"";
      height:100%;
      position: absolute;
      left: 0;
      top:0;
      width:5px;
    }
  }
  h4{
    font-size: 1.8rem;
  }
  h5{
    font-size: 1.6rem;
  }
  h6{
    font-size:1.5rem;
  }
  p + h2,h3,h4,h5,h6{
    margin-top:20px;
  }
  ul + h2,h3,h4,h5,h6{
    margin-top:20px;
  }
  ol + h2,h3,h4,h5,h6{
    margin-top:20px;
  }
  div + h2,h3,h4,h5,h6{
    margin-top:20px;
  }
  hr{
    background-color:#afa096;
    border: none;
    clear: both;
    height:2px;
    margin: 45px 0 15px;
  }
  ul{
    li{
      list-style:disc;
    }
  }
  ol{
    li{
        list-style-type: decimal;
    }
  }
  .wrap_col2{
    margin:15px 0;
    .col{
      margin-top: 15px;
    }
  }
  }
}
@media only screen and (max-width: 768px) {
  main{
    article{
      .wrap_col2{
        .col{
          width:100% !important;
        }
      }
    }
  }
}
@media only screen and (min-width: 767px) {
  main{
    article{

    p{
      &+p{
        margin-top:15px
      }
    }
    ul,ol{
      margin-top:20px;
      li{
        margin-bottom:5px;
        margin-left:20px;
      }
      &+p{
        margin-top:20px
      }
    }
    p + h2,h3,h4,h5,h6{
      margin-top:40px;
    }
    ul + h2,h3,h4,h5,h6{
      margin-top:40px;
    }
    ol + h2,h3,h4,h5,h6{
      margin-top:40px;
    }
    div + h2,h3,h4,h5,h6{
      margin-top:40px;
    }
    h1{
      font-size: 3.2rem;
    }
    h2,h3,h4,h5,h6{
      margin-bottom:25px;
    }
    h2{
      font-size:3.3rem;
    }
    h3{
      font-size:2.6rem;
      padding-left:20px;
      &:before{
        width:7px;
      }
    }
    h4{
      font-size: 2.4rem;
    }
    h5{
      font-size: 2rem;
    }
    h6{
      font-size:1.6rem;
    }
    .wrap_col2{
      display:flex;
      justify-content: space-between;
      margin:25px 0;
      h2,h3,h4,h5,h6{
        margin-top:0;
      }
      .col{
        margin-top: 0px;
        width:47%;
      }
    }
    }
  }
}

// @media only screen and (max-width: 769px) {
//   main{
//     table{
//       th,td{
//         display:block;
//         text-align:left;
//       }
//     }
//   }
// }
// 

.smartphoto-arrow-right a,.smartphoto-arrow-left a{
  background-repeat: no-repeat;
}
.smartphoto-img-clone {
  opacity: 0;
}
.smartphoto-arrows li{
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
  height: 50px;
  width: 50px;
}
.smartphoto-dismiss{
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  height: 30px;
  width: 30px;
  z-index: 9999;
}